body {
  font-family: "Roboto Mono";
  height: 100vh;
  margin: 0;
}

.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  user-select: none;
  font-variation-settings: "wght" 300;
}
